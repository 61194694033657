import React from 'react'
import ReactDOM from 'react-dom/client'
import { Loader } from '@react-three/drei'
import Overlay from './Overlay/Overlay'
import Scene from './Scene'
import './styles.css'  // Make sure to import your CSS

function App() {
  return (
    <>
      <Scene />
      <Overlay />
      <Loader />
    </>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)