import React, { useState, useEffect } from 'react';
import "./styles.css";

export default function Overlay() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999,
        color: '#fff',
        fontSize: '2rem',
        textAlign: 'center',
        padding: '20px',
      }}
    >
      <h1
        style={{
          fontSize: '5rem',
          color: '#66B3FF',
          marginLeft: '40rem', // Default margin for desktop
          textShadow: '0 0 15px rgba(135, 206, 235, 0.8)',
        }}
        className="overlay-title"
      >
        Coming Soon
      </h1>
    </div>
  );
}
